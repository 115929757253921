.token {
    font-weight: bold;
    border-top: 2px solid #9ED104;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center; /* Center title vertically */
    width: auto;
    text-align: center; /* Center content horizontally */
  }

  .token1 {
  
    color: #9ED104;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center; /* Center title vertically */
    width: auto;
    text-align: center; /* Center content horizontally */
  }
  
  .token-title {
    color: #fff;
    
  }

  /* Override the default toast style */
.react-toastify-toast {
  /* Your custom styles here */
  background-color: black;
  color: #9ED104;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Override the loading toast style */
.react-toastify__loading-bar {
  /* Your custom styles here */
  /* background-color: #9ED104; */
  background-color: black;
  color: #9ED104;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.sward-image {
  max-width: 100%;  /* Ensure it doesn't exceed the width of its parent */
  height: auto;  /* Maintain the aspect ratio */
  display: block;  /* Treat the image as a block-level element */
  margin: 0 auto;  /* Auto margins on the side to center it horizontally */
  padding: 5px 0;
}

/* token.css */
.checkmark-icon {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  vertical-align: middle; /* Aligns the icon with the middle of the text */
}

.email-text {
  vertical-align: middle; /* Aligns the text with the middle of the icon */
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #9ED104;
  font-family: Arial, sans-serif;
  font-weight: bold;
}


/* Styles for screens wider than 600px (desktops and tablets) */
@media (min-width: 600px) {
  .sward-image {
    width: 500px;  /* Fixed width for larger screens */
  }
}






  
 
  