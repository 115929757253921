.App, .body, .html {
  background: linear-gradient(to bottom, 
      rgb(20, 20, 20) 0%, /* Start color */
      rgb(14, 7, 7) 50%, /* Start color */
      rgb(0, 0, 0) 100% /* End color */
  );
  background-attachment: fixed; /* This makes the background fixed while scrolling */
  justify-content: center;

}

ul {
    list-style: none;
}

/* 
scrollbar custom */
::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--grayAccent);
    border-radius: 8rem;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--textLightColor);
  }