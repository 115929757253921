@keyframes pulseAnimation {
    0% { border-color: red; }
    33% { border-color: orange; }
    66% { border-color: yellow; }
    100% { border-color: red; }
}

.chart-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}

.chart2 {
    height: 700px;
    width: 1200px;
}

.chart {
  flex: 1;
}

.content {
  flex: 1; /* Allow the content to take available space */
}

.graph img {
  max-width: 1000px;
  height: auto; 
}

@media only screen and (max-width: 768px) {
   

    .button-85 {
        transform: translateX(18%); /* Center it horizontally */
    }

    .graph img {
      width: 400px; /* Adjust the width to make it larger on mobile */
      max-width: 1000px;
      height: auto; 
      margin: 0 auto; /* Center the image horizontally */
  }
}

.chartH2 {
    color: #fff;
    margin: 0; /* Remove default margin for h2 */
    padding: 10px 0; /* Add vertical padding for spacing */
}

.chartH3 {
  color: #fff;
  margin: 0; /* Remove default margin for h2 */
  padding: 10px 0; /* Add vertical padding for spacing */
}

.title {
    color: #fff;
    border-radius: 15px;
    font-weight: bold;
    background-color: #222;
    display: flex;
    justify-content: center;
    align-items: center; /* Center title vertically */
    width: auto;
    animation: pulseAnimation 2s linear infinite; /* Apply the pulsing animation */
    margin-top: 15px;
    margin-bottom: 15px;
}

.title2 {
    background: linear-gradient(to bottom, orange, black); /* Linear gradient from red to black */
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center; /* Center title vertically */
    color: #fff;
}

.left-image {
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  margin-right: 10px; /* Add space between the image and text */
  /* Add more styling as needed */
}

.title3 {
  background-color: transparent;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  position: relative; /* Add relative positioning */
}

.title3::before {
  /* Create a pseudo-element to serve as the fixed background */
  content: "";
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Place it below the content */
  background-attachment: fixed; /* Fixed background */
}

.title3 h2 {
  animation: marquee 9s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-align: center;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #9ED104,
    #fff,
    #fff,
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.button-851 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-align: center;
}

.button-851:before {
  content: "";
  background: linear-gradient(
    45deg,
    #fff,
    #9ED104,
    #fffb00,
    black
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-851:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.add-token {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.hr {
  width: 200px;
}

.nft {
  text-align: center;
  text-decoration: underline 1.5px orange;
}


