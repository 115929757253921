
.roadmap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .roadmap-phase {
    background-color: #1234; /* Ensure this is a valid color or use a proper hex code */
    border-radius: 10px;
    padding: 20px 10px;
    margin: 10px 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px; /* Controls width */
    min-height: 250px; /* Adjust this value as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will distribute space evenly */
  
}
  
  @keyframes glowing-border {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  /* Apply the glowing border effect when hovering over the roadmap phase */
  .roadmap-phase:hover .roadmap-border {
    animation: glowing-border 20s linear infinite;
  }
  
  .roadmap-phase h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #9ED104;
  }

  .roadmap-phase p {
    color:#fff;
    font-size: 15px;
    font-weight: bold;
    line-height: 30px;
  }
  
  /* Add more styling to make it visually appealing */
  